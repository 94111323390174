import React from "react"
import Styles from "./Tag.module.scss"
import Ps from "./Ui/Ps";


const Tag = (
    {
        text
    }: {
        text: string
    }) => (
    <div className={Styles.container}>
        <Ps>
            {text}
        </Ps>
    </div>
)

export default Tag
