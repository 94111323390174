import React from "react"
import Styles from "./ProjectList.module.scss"
import {ProjectsInfo} from "../date/ProjectInfo";
import ProjectBox from "./ProjectBox";
import classNames from "classnames";

const ProjectList = (
    {
        image = false
    }: {
        image?: boolean
    }) => {

    return (
        <div className={Styles.content}>
            {ProjectsInfo.map((project, index) =>
                <div className={Styles.project} key={index}>
                    <ProjectBox
                        project={project}
                        key={index}
                    />
                    <div className={classNames(Styles.image, {[Styles.descHidden]: image})}>
                        <img src={project.image} alt={"rplanet project"}/>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ProjectList
