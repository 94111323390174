import img_rplanet from "../images/landing/img_rplanet.png"
import img_prospector from "../images/landing/img_prospector.png"
import img_tb from "../images/landing/img_tb.png"
import img_pstake from "../images/landing/img_pstake.png"

export interface IProjectInfo {
    tags: string[],
    name: string,
    description: string,
    isGame: boolean,
    image: string,
    link: string
}

export const ProjectsInfo: IProjectInfo[] = [{
    tags: ["MMO Conquest Game", "NFT Staking", "Metaverse"],
    name: "R-PLANET",
    description: "R-Planet Conquest is a central game of the R-Planet Metaverse. At the time of the major threat of the Ahnangs invasion, advanced animals were divided into three factions. It is time for each inhabitant of R-Planet to decide which battle strategy to choose. Build a base, develop an army, unite with allies, fight and win. The first one who builds the Superweapon will win the battle and save the R-Planet.",
    isGame: true,
    image: img_rplanet,
    link: "https://rplanet.app/"
}, {
    tags: ["MMO", "Economic Strategy", "Token mining"],
    name: "PROSPECTORS",
    description: "Prospectors is a metaverse of gold-seekers, the first of its kind MMO dapp, and crypto game, where players can experience the thrilling life of a gold miner during the gold rush epoque. This economic strategy game gives players endless opportunities to earn crypto gold by utilizing blockchain technology.",
    isGame: true,
    image: img_prospector,
    link:  "https://prospectors.io/"
}, {
    tags: ["Adventure", "Strategy", "Step-by-Step NFT-Card Game"],
    name: "TRIBAL BOOKS",
    description: "Tribal Books is a free-to-play online digital collectible step-by-step NFT-card game. In each round, players compete with each other using their heroes, magical cards, and magical forces from the Tribal Books. Only one player will become a winner. The game is currently in the development stage. Right now, players can call the hero for themselves by staking Tribal Books assets and earning enough Mana to join the ritual of Mana Enchanting.",
    isGame: true,
    image: img_tb,
    link: "https://tribalbooks.io/"
}, {
    tags: ["Staking system", "NFT", "WAX blockchain", "Reward token"],
    name: "PEPPER STAKE",
    description: "Pepper Stake is a service for the effortless creation of staking utilities within the WAX ecosystem. Anyone can create a staking system where users can stake NFTs or a token built on the WAX blockchain and be rewarded. The service is fully decentralized, so anyone can use its UI and/or use any WAX explorer to communicate directly with the Pepper Stake smart contract.",
    isGame: false,
    image: img_pstake,
    link: "https://pepperstake.online/"
}];