import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import StylesLayout from "../components/Layout.module.scss";
import Styles from "./project.module.scss";
import H1 from "../components/Ui/H1";
import mascotBG from "../images/landing/img_mascotProjects.png";
import ProjectList from "../components/ProjectList";

const ProjectsPage = () => (
    <Layout lightThem={false}>
        <SEO title="Full stack game development"
             description="WECAN distributed ledger game development team leverages emerging technology to create gaming
             applications utilizing EOSIo software."/>
        <div className={StylesLayout.content}>
            <div className={Styles.banner}>
                <H1>
                    We create full-stack blockchain dapps & games.
                </H1>
                <div className={Styles.image}>
                    <img src={mascotBG} alt={"mascotBG"}/>
                </div>
            </div>
            <ProjectList/>
        </div>
    </Layout>
);

export default ProjectsPage;
