import React from "react"
import Styles from "./ProjectBox.module.scss"
import H2 from "./Ui/H2";
import Pb from "./Ui/Ps";
import Buttons, {EButtonType} from "./Ui/Buttons";
import Tag from "./Tag";
import {IProjectInfo} from "../date/ProjectInfo";

const ProjectBox = (
    {
        project,
    }: {
        project: IProjectInfo,
    }
) => {

    return (
        <div className={Styles.container} id={project.name}>
            <div className={Styles.leftWrap}>
                <div className={Styles.tagWrap}>
                    {project.tags.map(tag => <Tag text={tag} key={tag}/>)}
                </div>
                <H2>{project.name}</H2>
                <div className={Styles.description}>
                    <Pb>{project.description}</Pb>
                </div>
                <div className={Styles.btn}>
                    <a href={project.link} target={"_blank"}>
                        <Buttons text={project.isGame ? "VIEW GAME" : "VIEW SITE"} type={EButtonType.PRIMARY} icon={true}/>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ProjectBox
